.loginbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
.iconsizecolor{
  color: green;
  font-size: 70px;
 
}
.width300{
  width: 300px !important;
}
.registerbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
.newbutton{
  border-radius: 30px !important;
  background-color: #00b388;
  font-size: 20px;
}
.whitecolor{
  color: #fff !important;
}
.registerbutton2{
    background-color: #00b388;
    color: #fff;
    border-radius: 30;
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
    padding: 5px !important;
  }
  .registerbutton3{
    background-color: #00b388;
    color: #fff;
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
    padding: 5px !important;
  }